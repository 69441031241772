const GET_CLUSTER_DATA_SUCCESS = 'GET_CLUSTER_DATA_SUCCESS';
const GET_CLUSTER_DATA_START = 'GET_CLUSTER_DATA_START';
const GET_CLUSTER_DATA_FAILURE = 'GET_CLUSTER_DATA_FAILURE';

const initialState = {
  issetLoading: false,
  clusterData: [],
  error: null,
};

const clusterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLUSTER_DATA_START:
      return {
        ...state,
        issetLoading: true,
        error: null,
      };
    case GET_CLUSTER_DATA_SUCCESS:
      return {
        ...state,
        issetLoading: false,
        clusterData: action.payload,
      };
    case GET_CLUSTER_DATA_FAILURE:
      return {
        ...state,
        issetLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default clusterDataReducer;

// // ** Initial State
// const initialState = {
//   clusterData: [],
// }

// const clusterDataReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case 'GET_DATA':
//       return {
//         ...state,
//         clusterData: action.allData,
//       };
//     default:
//       return state
//   }
// }
// export default clusterDataReducer
