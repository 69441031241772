const initialState = null;
  

const clusterViewReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'HANDLE_CURRENT_CLUSTER_VIEW':
       // console.log(action.val)
        return action.val;
      default:
            return state
  }
}
  
  export default clusterViewReducer