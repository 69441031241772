const initialState = {}
  

const filtersReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'HANDLE_FILTERS':
        console.log(action.val)
        return action.val;
      default:
            return state
  }
}


const initialState2={filter:{"Relevant Company":"0","Relevant Designation":"0","Geography":"0","Experience":[0]}};
const clusterFilterReducer = (state = initialState2, action) => {
  switch (action.type) {
    case 'HANDLE_CFILTERS':
      console.log(action.val)
      return action.val;
    default:
          return state;
}
}

export {clusterFilterReducer}




const initialState3 = {}
  

const clusterVcSettingsReducer = (state = initialState3, action) => {
    switch (action.type) {
      case 'HANDLE_VCFILTERS':
        console.log(action.val)
        return action.val;
      default:
            return state
  }
}

export {clusterVcSettingsReducer}




  
  export default filtersReducer