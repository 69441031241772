// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import skinThemeReducer from './skin'
import dataTables from './data-table'
import suggestionsReducer from './suggestions'
import filtersReducer from './filters'
import { clusterFilterReducer,clusterVcSettingsReducer } from './filters'
import clusterDataReducer from './cluster'
import { clusterShortlistExpertsReducer } from './shortlistExperts'
import bubbleChartDataReducer from './bubble-chart'
import circlePackDataReducer from './circlePackSegmentation'
import vcDataReducer from './vc';
import ExistingExpertReducer from './ExistingExpert';
import clientExpertsDataReducer from './ClientExpert'
import clusterFilterSideboxReducer from './cluster-filters'
import expertFilterListReducer from './expert-filters'
import { expertFilterUsersReducer, expertCurrentFilterReducer, checkIsFilterReducer } from './expert-filters'
import SelectedExpertReducer from './selected-expert'
import UserListReducer from './user'
import clusterViewReducer from './vc-view'
import { UserDataReducer } from './user'
import requestFormReducer from './request-form'
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  skinThemeReducer,
  dataTables,
  suggestionsReducer,
  filtersReducer,
  clusterFilterReducer,
  clusterVcSettingsReducer,
  clusterDataReducer,
  bubbleChartDataReducer,
  clusterShortlistExpertsReducer,
  vcDataReducer,
  ExistingExpertReducer,
  clientExpertsDataReducer,
  clusterFilterSideboxReducer,
  expertFilterListReducer,
  expertFilterUsersReducer,
  expertCurrentFilterReducer,
  checkIsFilterReducer,
  SelectedExpertReducer,
  UserListReducer,
  circlePackDataReducer,
  clusterViewReducer,
  UserDataReducer,
  requestFormReducer
})

export default rootReducer
