const initialState = ""
  

const clusterFilterSideboxReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'HANDLE_CLUSTER_FILTER_SIDEBOX':
        console.log(action.val)
        return action.val;
      default:
        return state
  }
}




const initialState2 = []
  

const clusterFilterFieldsReducer = (state = initialState2, action) => {
    switch (action.type) {
      case 'HANDLE_FILTER_FIELDS':
        console.log(action.val)
        return action.val;
      default:
        return state
  }
}


  
  export default clusterFilterSideboxReducer
  export {clusterFilterFieldsReducer}