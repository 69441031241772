const initialState = {}
const initialState_user = {};
const UserListReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_USER_LIST':
      //console.log("UserData...",action.allData)
      return {
        ...state,
        data: action.allData,

      }
    case 'GET_SHAREABLE_USER_LIST':
      //console.log("UserData...",action.allData)
      return {
        ...state,
        shareAbleUserdata: action.allData,

      }

    default:
      return state
  }
}

const UserDataReducer = (state = initialState_user, action) => {
  switch (action.type) {

    case 'HANDLE_UPDATE_LOGGED_DATA':
      //console.log("UserData...",action.allData)
      return action.val;


      default:
        return state;
    }
  }

export {UserDataReducer}
export default UserListReducer;
