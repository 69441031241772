const initialState = {}

const SelectedExpertReducer = (state = initialState, action) => {
    switch (action.type) {
      
      case 'GET_SELECTED_EXPERT_DATA':
           console.log("Received Redux ExistingData:",action.allData,state)
          return {
            ...state,
            expertData: action.allData,
     
          }
      case 'HANDLE_SELECTED_EXPERT_LIST_UPDATE':
          let expData = [...state.expertData];
          return {
            ...state,
            expertData: [...expData,action.val]
          }    

        default:
          return state
  }
  }

  export default SelectedExpertReducer;